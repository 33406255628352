import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`
    #root {
        position: relative;
    }

.ocean { 
    height: 5%;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background: #015871;
  }
  
  .wave {
    background: url("/img/svg/wave.svg") repeat-x; 
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.5
  }
  
  .wave:nth-of-type(2) {
    top: -175px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
  }
  
  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }
  
  @keyframes swell {
    0%, 100% {
      transform: translate3d(0,-25px,0);
    }
    50% {
      transform: translate3d(0,5px,0);
    }
  }

  .bubbles{
    position:absolute;
    width:100%;
    bottom: 0;
    z-index:0;
    overflow:hidden;
    top:0;
    left:0;
  }
  .bubble{
    position: absolute;
    bottom:-100px;
    width:80px;
    height: 80px;
    background:#f1f1f1;
    border-radius:50%;
    opacity:0.5;
    animation: rise 10s infinite ease-in;
  }
  .bubble:nth-child(1){
    width:80px;
    height:80px;
    left:10%;
    animation-duration:8s;
  }
  .bubble:nth-child(2){
    width:40px;
    height:40px;
    left:20%;
    animation-duration:5s;
    animation-delay:1s;
  }
  .bubble:nth-child(3){
    width:100px;
    height:100px;
    left:35%;
    animation-duration:7s;
    animation-delay:2s;
  }
  .bubble:nth-child(4){
    width:160px;
    height:160px;
    left:50%;
    animation-duration:11s;
    animation-delay:0s;
  }
  .bubble:nth-child(5){
    width:70px;
    height:70px;
    left:55%;
    animation-duration:6s;
    animation-delay:1s;
  }
  .bubble:nth-child(6){
    width:90px;
    height:90px;
    left:65%;
    animation-duration:8s;
    animation-delay:3s;
  }
  .bubble:nth-child(7){
    width:180px;
    height:180px;
    left:70%;
    animation-duration:12s;
    animation-delay:2s;
  }
  .bubble:nth-child(8){
    width: 50px;
    height: 50px;
    left:80%;
    animation-duration:9s;
    animation-delay:2s;
  }
  .bubble:nth-child(9){
    width:30px;
    height:30px;
    left:70%;
    animation-duration:8s;
    animation-delay:1s;
  }
  .bubble:nth-child(10){
    width:180px;
    height:180px;
    left:25%;
    animation-duration:10s;
    animation-delay:4s;
  }
  .bubble:nth-child(11){
    width:80px;
    height:80px;
    left:24%;
    animation-duration:7s;
  }
  .bubble:nth-child(12){
    width:40px;
    height:40px;
    left:65%;
    animation-duration:9s;
    animation-delay:3s;
  }
  .bubble:nth-child(13){
    width:100px;
    height:100px;
    left:45%;
    animation-duration:8s;
    animation-delay:1s;
  }
  .bubble:nth-child(14){
    width:160px;
    height:160px;
    left:90%;
    animation-duration:16s;
    animation-delay:0s;
  }
  .bubble:nth-child(15){
    width:70px;
    height:70px;
    left:15%;
    animation-duration:8s;
  }
  .bubble:nth-child(16){
    width:90px;
    height:90px;
    left:0%;
    animation-duration:10s;
    animation-delay:7s;
  }
  @keyframes rise{
    0%{
      bottom:-100px;
      transform:translateX(0);
    }
    50%{
      transform:translate(100px);
    }
    100%{
      bottom:5000px;
      transform:translateX(-200px);
    }
  }

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #DD5B5E;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #DD5B5E 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #DD5B5E;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: #333;
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #DD5B5E;

        :hover {
            color: #DD5B5E;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;
