import { Col, Row } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  NavLink,
  Extra,
  LogoContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <Extra>
        <Container>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <Col span={1}>
            <Row gutter={16} justify="space-between" style={{position: 'relative'}}>
              <div style={{
                position: 'absolute',
                width: 300,
                bottom: -50,
                left: -234,
                textAlign: 'right'
              }} >Vectors by <a href="https://www.vecteezy.com/free-vector/casino">Vecteezy</a></div>
              <span style={{
                position: 'absolute',
                width: 300,
                bottom: -30,
                left: -234,
                textAlign: 'right'
              }}>Contact us at <a href="mailto: team@tiltedfish.fun">team@tiltedfish.fun</a></span>
              <SocialLink
                href="https://github.com/solanaGames"
                src="github.svg"
              />
              <SocialLink
                href="https://twitter.com/TiltedFishGames"
                src="twitter.svg"
              />
              </Row>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
